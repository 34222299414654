.ar-report-menu-section-container {
    justify-content: flex-start;
    display: flex;
    font-size: 14px;
    width: 100%;
    height: 20px;
    margin-left: -16px;

    button.ar-report-section-menu-button {
        color: #9BA0AA;
        font-size: 11px;
        font-weight: 400;
        height: 20px;
        transition: 0s;

        i.roche-icon.hide-eye {
            background-size: contain;
            background-image: url('../../../common/resources/icons/hide-gray.svg');
            margin-right: 8px;
            height: 13px;
            width: 13px;
            vertical-align: text-bottom;
        }
    }

    button.ar-report-section-menu-button:hover {
        color: #162242;
    }
}

.ar-report-menu-section-container:hover {
    button.ar-report-section-menu-button {
        i.roche-icon.hide-eye {
            background-image: url('../../../common/resources/icons/hide-blue.svg');
        }
    }
}

.ar-report-section-menu-popover {
    .ant-popover-content {
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
        max-height: 490px;
        max-width: 340px;
        overflow-x: auto;

        .ant-popover-inner-content {
            padding: 15px;
        }

        .ar-report-section-menu-topic {
            font-size: 14px;
            font-weight: 400;

            .title-container {
                min-width: 125px;
            }
        }

        .ar-report-section-menu-topic:not(:last-child) {
            margin-bottom: 20px;
        }

        .ar-report-section-menu-topic {
            display: flex;
            gap: 15px;

            .title-container {
                flex: 1 1;
            }
        }
    }
}
@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;