.ar-report-menu-container {
    justify-content: end;
    display: flex;
    font-size: 14px;
    width: 100%;

    i.roche-icon.hide-eye {
        background-size: contain;
        background-image: url('../../../common/resources/icons/hide-gray.svg');
        margin-right: 8px;
        height: 20px;
        width: 20px;
        vertical-align: text-bottom;
    }

    button.ar-report-layout-menu-button {
        color: #9BA0AA;
        transition: 0s;
    }

    button.ar-report-layout-menu-button:hover {
        color: #162242;
    }
}

.ar-report-menu-container:hover {
    i.roche-icon.hide-eye {
        background-image: url('../../../common/resources/icons/hide-blue.svg');
    }
}

.ar-report-layout-menu-popover {
    .ant-popover-content {
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
        max-height: 490px;
        max-width: 340px;
        overflow-x: auto;

        .ant-popover-inner-content {
            padding: 0;
        }

        .ar-report-layout-menu-section {
            border-bottom: 1px solid #E4E5ED;
            margin-bottom: 5px;
            padding: 15px 15px 0 15px;

            .ar-report-layout-menu-section-title {
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            .ar-report-layout-menu-topic {
                font-size: 12px;
                font-weight: 400;
                padding-left: 24px;
                margin-bottom: 20px;
            }

            .ar-report-layout-menu-section-title, 
            .ar-report-layout-menu-topic {
                display: flex;
                gap: 15px;

                .title-container {
                    flex: 1 1;
                }
            }
        }
    }
}
@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;